import React, { useState } from "react";
import { db } from "./firebase";
import "./RSVPForm.css";

const RSVPForm = () => {
	const [name, setName] = useState("");
	const [hasPlusOne, setHasPlusOne] = useState(false);
	const [plusOneName, setPlusOneName] = useState("");
	const [submitted, setSubmitted] = useState(false);

	const handleSubmit = async (e) => {
		e.preventDefault();
		try {
			await db.collection("rsvps").add({
				name,
				plusOne: hasPlusOne ? plusOneName : null,
			});
			alert(
				"Thank you for coming! We look forward to seeing you at the party!\n\nSincerely,\nDavid and Martina"
			);
			setSubmitted(true);
			setName("");
			setHasPlusOne(false);
			setPlusOneName("");
		} catch (error) {
			console.error("Error adding RSVP: ", error);
			alert("Error submitting RSVP. Please try again later.");
		}
	};

	return (
		<div className="RSVPForm">
			<h2>Engagement Party RSVP</h2>
			<p>
				We are thrilled to announce our engagement and would love for you to
				join us in celebrating this special moment.
			</p>

			<p>
				Please come and share in our joy, laughter, and love as we take this
				next step together.
			</p>
			<p>
				<strong>Date:</strong> Saturday, June 22nd 2024
			</p>
			<p>
				<strong>Time:</strong> 7:00 PM
			</p>
			<p>
				<strong>Location:</strong> El Barrio Egypt
			</p>
			<div className="map-container">
				<iframe
					src="https://www.google.com/maps/embed?pb=!1m14!1m8!1m3!1d13808.16267303815!2d31.32809!3d30.0930216!3m2!1i1024!2i768!4f13.1!3m3!1m2!1s0x145815b83d3ea765%3A0xaf9533ff0dd25882!2sEL%20BARRIO%20EGYPT!5e0!3m2!1sen!2seg!4v1718162227572!5m2!1sen!2seg"
					width="100%"
					height="100%"
					style={{ border: 0 }}
					allowFullScreen=""
					loading="lazy"
					referrerPolicy="no-referrer-when-downgrade"
					title="Event Location"
				></iframe>
			</div>
			<p>
				<strong>Min. Charge:</strong> 600 EGP
			</p>
			<p>Consider this invitation extended to your plus one!</p>
			<p>Your presence will make our day even more magical.</p>
			<p>We can't wait to celebrate with you!</p>
			<p>
				<strong>With love,</strong>
			</p>
			<p>Feel free to BYOB at 300 EGP for wine and 500 EGP for whiskey</p>

			<form onSubmit={handleSubmit}>
				<input
					type="text"
					placeholder="Your Name"
					value={name}
					onChange={(e) => setName(e.target.value)}
					required
				/>
				<div className="plusOneCheckbox">
					<label>
						<input
							type="checkbox"
							checked={hasPlusOne}
							onChange={(e) => setHasPlusOne(e.target.checked)}
						/>
						Bring your Plus One
					</label>
				</div>
				{hasPlusOne && (
					<div className="plusOneInput">
						<input
							type="text"
							placeholder="Plus One's Name"
							value={plusOneName}
							onChange={(e) => setPlusOneName(e.target.value)}
							required
						/>
					</div>
				)}
				<button type="submit">Submit RSVP</button>
			</form>
		</div>
	);
};

export default RSVPForm;
