import firebase from "firebase/compat/app";
import "firebase/compat/firestore";

const firebaseConfig = {
	apiKey: "AIzaSyBoxj_mqb2BY8UkAMslfGTTNSvmdGpMD0o",
	authDomain: "rsvp-74bad.firebaseapp.com",
	projectId: "rsvp-74bad",
	storageBucket: "rsvp-74bad.appspot.com",
	messagingSenderId: "223240217960",
	appId: "1:223240217960:web:41faa32fd62dff8e51e2dd",
};

firebase.initializeApp(firebaseConfig);

export const db = firebase.firestore();
