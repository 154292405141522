import React, { useEffect, useState } from "react";
import { db } from "./firebase";
import "./AdminPage.css";

const AdminPage = () => {
	const [rsvps, setRsvps] = useState([]);

	useEffect(() => {
		const fetchRsvps = async () => {
			try {
				const snapshot = await db.collection("rsvps").get();
				const rsvpsData = snapshot.docs.map((doc) => ({
					id: doc.id,
					...doc.data(),
				}));
				setRsvps(rsvpsData);
			} catch (error) {
				console.error("Error fetching RSVPs: ", error);
			}
		};

		fetchRsvps();
	}, []);

	// Add fields to count attendees and plus ones
	let mainAttendees = 0;
	let plusOnes = 0;
	let index = 0;

	return (
		<div className="admin-container">
			<h2>Admin Page</h2>
			<table className="admin-table">
				<thead>
					<tr>
						<th>#</th>
						<th>Name</th>
						<th>Plus One</th>
					</tr>
				</thead>
				<tbody>
					{rsvps.map((rsvp) => (
						<React.Fragment key={rsvp.id}>
							<tr>
								<td>{++index}</td>
								<td>{rsvp.name}</td>
								<td></td>
							</tr>

							{rsvp.plusOne && (
								<tr>
									<td>{++index}</td>
									<td>{rsvp.plusOne}</td>

									<td>
										<input type="checkbox" disabled checked />
									</td>
								</tr>
							)}
						</React.Fragment>
					))}
				</tbody>
				<tfoot>
					<tr>
						<td colSpan="3">Total Attendees: {index}</td>
					</tr>
				</tfoot>
			</table>
		</div>
	);
};

export default AdminPage;
