import React from "react";
import { BrowserRouter, Router, Route, Switch, Routes } from "react-router-dom";
import "./App.css";
import RSVPForm from "./RSVPForm";
import AdminPage from "./AdminPage";

function App() {
	return (
		<div className="App">
			<header className="App-header">
				<div className="overlay"></div> {/* Add overlay */}
				<h1>David & Martina </h1>
			</header>
			<BrowserRouter>
				<Routes>
					<Route exact path="/" element={<RSVPForm />} />
					<Route path="/admin" element={<AdminPage />} />
				</Routes>
			</BrowserRouter>
		</div>
	);
}

export default App;
